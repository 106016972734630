// node modules
import { createUseStyles } from "react-jss";

// local files
import { Theme } from "../../theme";

export const useStyles = createUseStyles({
    errorMessageClass: {
        textAlign: 'left',
        padding: '3px 0',
        color: ({ theme: { palette } }: Theme) => palette.error
    },
    validatedInputWrapper: {
        width: '100%'
    },
    hidden: {
        visibility: 'hidden',
    },
});
