// node modules
import React, { ReactNode, FC } from "react";

//local components
import { Typography } from "../../typography";

// local files
import { useStyles } from "./styles";
import { theming } from "../../../theme";

export interface SimpleButtonProps {
    label: string | ReactNode;
    className?: string;
    width?: number | string;
    disabled?: boolean;
    centered?: boolean;
    onClick?: () => void;
    children?: string | ReactNode;
    textTransform?: 'capitalize' | 'uppercase' | 'lowercase' | 'none'
}

export const SimpleButton: FC<SimpleButtonProps> = ({
    className = '',
    label,
    width,
    disabled,
    centered= false,
    onClick,
    children,
    textTransform= 'none',
...otherProps
}) => {
    const { root, disabledClass, centeredLabel } = useStyles({ theme: theming.useTheme() });
    const btnClassName = `${root} ${className} ${disabled && disabledClass}`;
    const handleClick = () => {
        if (disabled) return;

        if(onClick) onClick();
    }

    return (
        <button
            onClick={handleClick}
            style={{ width, textTransform }}
            className={btnClassName}
            {...otherProps}
        >
            {label ? (<Typography variant="title" className={centered ? centeredLabel : ''}>{label}</Typography>) : null}
            {children}
        </button>
    )
};
