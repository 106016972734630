export type Palette = {
    primary: string;
    light: string;
    black: string;
    white: string;
    extra_light: string;
    gray: string;
    light_gray: string;
    gray_200: string;
    error: string;
    dark_black: string;
    gray_300: string;
    gray_400: string;
    gray_500: string;
    primary_dark: string;
    boxShadow: string;
    light_hover: string;
}

export const palette = {
    boxShadow: '0 0 0 0.2rem rgb(145 156 248 / 50%)',
    primary: '#4B46EC',
    primary_dark: '#1d17ef',
    light: '#F0F0FF',
    light_hover: '#d9d9f7',
    black: '#121620',
    dark_black: '#232323',
    white: '#ffffff',
    extra_light: '#dee5f9',
    gray: '#f0f0f0',
    light_gray: '#c7c7c7',
    gray_200: '#8e8e93',
    gray_300: '#C5C5D1',
    error: '#FF5758',
    gray_400: '#E0E3EB',
    gray_500: '#868C99'
}
