// node modules
import { createUseStyles } from 'react-jss'

// local files
import { Theme } from "../../../theme";

export const useStyles = createUseStyles({
    root: {
        borderRadius: '2em',
        background: ({ theme: { palette } }: Theme) => palette.primary,
        color: ({ theme: { palette } }) => palette.light,
        transition: 'background .3s, color .3s, box-shadow 2s',

        '&:hover': {
            background: ({ theme: { palette } }) => palette.primary_dark
        },
        '&:active': {
            boxShadow: ({ theme: {palette} }) => palette.boxShadow
        }
    }
})