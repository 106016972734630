// node modules
import { createUseStyles } from "react-jss";

// local files
import {
    labelClassName,
    labelWrapperRoot,
    inputSelectCommonStylesProps,
    activeLabelClassName,
    disabled,
    errorClass
} from "../../theme/common";
import { alignment } from "../../theme/alignment";
import SFProText from '../../assets/fonts/SF-Pro-Text-Regular.otf';

const ADORNMENT_WIDTH = 40;

export const useStyles = createUseStyles({
    '@font-face': {
        fontFamily: "SF Pro Text",
        src: `url('${SFProText}') format("opentype")`
    },
    root: {
       ...labelWrapperRoot,
        display: 'flex',
        paddingRight: 16
    },
    input: {
        ...inputSelectCommonStylesProps,
        width: ({ adornmentEnd, adornmentStart }) => `calc(100% - ${adornmentStart ? ADORNMENT_WIDTH : 0}px - ${adornmentEnd ? ADORNMENT_WIDTH : 0}px)`,
        textAlign: 'left',
        fontFamily: 'SF Pro Text',
        '-webkit-tap-highlight-color': 'transparent'
    },
    inputAdorned: {
        left: '30px !important'
    },
    inputAdornedActive: {
        left: '32px !important'
    },
    unlabeled: {
        padding: '12px 0 12px .75rem'
    },
    labelClass: {
        ...labelClassName
    },
    active: {
        ...activeLabelClassName
    },
    disabledClass: {
        ...disabled
    },
    errorClass: {
        ...errorClass
    },
    typeWrapper: {
        ...alignment.center,
        paddingLeft: 11,
        whiteSpace: 'nowrap',
        width: 40
    },
    typeWrapperStart: {
        ...alignment.center,
        paddingRight: 11
    },
    clearIconClass: {
        cursor: 'pointer',
        paddingLeft: 11
    }
});
