// local files
import { Theme } from "./";

export const buttonInputCleanStyles = {
    border: 'none',
    outline: 'none',
    background: 'none',
}

export const labelClassName = {
    color: ({ theme: { palette } }: Theme) => palette.black,
    position: 'absolute',
    top: 0,
    left: 'calc(.75rem + 1px)',
    fontSize: '.875rem',
    lineHeight: '1.6rem',
    transform: 'translateY(calc(.75rem - 1px))',
    transition: 'all .2s ease',
    pointerEvents: 'none'
}

export const activeLabelClassName = {
    color: ({ theme: { palette } }: Theme) => palette.gray_200,
    fontSize: '.75rem',
    lineHeight: '1.8rem',
    transform: 'translateY(0)'
}

export const labelWrapperRoot = {
    position: 'relative',
    border: ({ theme: { palette } }: Theme) => `1px solid ${palette.light_gray}`,
    borderRadius: '.625rem',
    boxSizing: 'border-box'
}

export const inputSelectCommonStylesProps = {
    ...buttonInputCleanStyles,
    all: 'unset',
    display: 'block',
    width: '100%',
    padding: '20px 0 5px .75rem',
    boxSizing: 'border-box',
    fontSize: '1rem',
    lineHeight: '1rem',
    borderRadius: '.625rem',
}

export const disabled = {
    cursor: 'not-allowed',
    opacity: .4
}

export const errorClass = {
    borderColor: ({ theme: { palette } }: Theme) => palette.error
}