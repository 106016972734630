// node modules
import React, { FC } from 'react';

// local components
import { SimpleButton } from '../simple_button';

// local files
import { useStyles } from './styles';
import { theming } from "../../../theme";

export interface PrimaryButtonProps {
    label: string;
    className?: string;
    disabled?: boolean;
    children?: any;
    onClick?: () => any;
}


export const PrimaryButton: FC<PrimaryButtonProps> = ({
    className = '',
    label = '',
    children,
    ...otherProps
}) => {
    const theme = theming.useTheme();
    const { root } = useStyles({ theme });
    const primaryBtnClassName = `${root} ${className}`;

    return (
        <SimpleButton  className={primaryBtnClassName} label={label} {...otherProps}>
            {children}
        </SimpleButton>
    )
}
