// node modules
import { createContext, ReactNode } from 'react';
import { createTheming } from 'react-jss';

// local files
import { palette, Palette } from "./palette";

export type Theme =  {
    theme: { palette: Palette },
    adornmentEnd?: string | ReactNode,
    adornmentStart?: string | ReactNode
}

const ThemeContext = createContext({ palette });

export const theming = createTheming(ThemeContext);
