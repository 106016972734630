// node modules
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';

// local files
import { ValidationQueryType, ValidationTypeProps } from "./";

const typeValidator = {
    emptyString: (value: string) => !isEmpty(value),
    email: (value: string) => !isEmail(value)
}

export const validator = {
    maxLength: (value: number | string, options: ValidationQueryType) => value.toString().trim().length > options.value,
    minLength: (value: number | string, options: ValidationQueryType) => value.toString().trim().length < options.value,
    // @ts-ignore
    type: (value: string, { value: type }: ValidationTypeProps) => typeValidator[type](value)
}

export const numberValidator = {
    moreThen: (value: number, options: ValidationQueryType) => !(value > options.value),
    lessThen: (value: number, options: ValidationQueryType) => !(value < options.value),
    equal: (value: number, options: ValidationQueryType) => value !== options.value
}
